$( document ).ready(function() {
  $(".edit_full_address").click(function() {

    $('.toggle-block').toggle();
    $('.full_address').toggle();
    $(this).toggle();
    M.updateTextFields();
  })

  var width = $(window).width();
  if (width < 415) {
    $('.s6').each(function() {
      $(this).attr('class', 'input-field col s12')
    })
  }

  $('#zip').on('input', function() {
    if (this.value.length > this.maxLength)
      this.value = this.value.slice(0, this.maxLength)
  });
})

