function is_index_card() {
  return (location.pathname.includes("locations") && location.pathname === "/locations") || location.pathname === "/";
}

function is_edit_card() {
  return (location.pathname.includes("edit") &&  location.pathname.includes("locations"));
}

function is_new_card() {
  return location.pathname.includes("new");
}

function is_sign_up() {
  return location.pathname.split("/").includes("sign_up");
}

function getCoordinates(country, location) {
  $.ajax({
    url: "/cards/get_geocode",
    type: "get",
    data: {country: country, location: location},
    success: function(data) {
      if ('error' in data) {
        console.log("ERROR MESSAGE: "+ data.error)
      } else {
        console.log('success, find geocode')
        $("#lat").val(data.latitude)
        $("#lon").val(data.longitude)
        updateGoogleMap(data.longitude, data.latitude)
      }
    },
    error: function(data) {
    }
  })
}

function updateGoogleMap(lon, lat) {
  $('#map-form').locationpicker("location", {latitude: lat, longitude: lon});
}

function resizeTextarea() {
  $('.materialize-textarea').each(function (index) {
    M.textareaAutoResize(this);
  });
}

$( document ).ready(function() {
  $('.sidenav').sidenav();

  function removeMapOverlap() {
    if($('.map .map-overlap').length){
      $('.map-overlap').remove();
    }
  };

  function toggleReadOnlyAttr() {
    $('input[type=text], textarea').each(function(i, el) {
      $(el).attr('readonly', true);
    })
  
    $('input[type=text], textarea').focus(function(e) {
      $(this).attr('readonly', false);
      if($(this).data('old-name') == undefined) {
        $(this).data('old-name', $(this).attr('name'));
      }
      $(this).attr('name', Math.random().toString(36).substring(5))
    });
  
    $('input[type=text], textarea').blur(function(e) {
      $(this).attr('name', $(this).data('old-name'));
    });
  }

  function preventAutocomplete() {
    pca.on("options", function(type, key, options) {
      options.suppressAutocomplete = false;
    });
  };

  $(".toggle-password").click(function() {
    $(this).text() === 'visibility_off' ? $(this).text('visibility') : $(this).text('visibility_off')
    var input = $($(this).closest('.input-field').find('input'));
    if (input.attr("type") == "password") {
      input.attr("type", "text");
    } else {
      input.attr("type", "password");
    }
  });
  if (is_sign_up()) {
    //toggle password
  }

  // slow exit warnings
  $('#alert_close').click(function(){
    $( "#alert_box" ).fadeOut( "slow", function() {
    });
  });

  // IS_INDEX_PAGE
  if(is_index_card()){
    $('.fixed-action-btn').floatingActionButton();

  // IS_EDIT_CARD
  } else if (is_edit_card()) {
    //set long and ltd after autocomplit
    toggleReadOnlyAttr();
    preventAutocomplete();
    pca.on("load", function(type, id, control) {
      control.listen("populate", function(address) {
        M.updateTextFields();
        resizeTextarea()
        //get coordinates
        getCoordinates(address.CountryIso2, address.PostalCode || address.Label)

        updateGoogleMap($("#lon").val(), $("#lat").val());
        removeMapOverlap();
      });
    });

  // IS_NEW_CARD
  } else if (is_new_card()) {
    //set long and ltd after autocomplete
    toggleReadOnlyAttr()
    preventAutocomplete();
    pca.on("load", function(type, id, control) {
      control.listen("populate", function(address) {
        M.updateTextFields();
        resizeTextarea()
        //get coordinates
        getCoordinates(address.CountryIso2, address.PostalCode || address.Label)

        updateGoogleMap($("#lon").val(), $("#lat").val());
        removeMapOverlap();
      });
    });

 // IS_SHOW_CARD
  } else {
    //open images in fill size
    $('img').click(function() {
      var modal = document.getElementById("myModal");

      var img = this;
      var modalImg = document.getElementById("img01");
      modal.style.display = "block";
      modalImg.src = this.src;
      var span = document.getElementsByClassName("close")[0];

      span.onclick = function() {
        modal.style.display = "none";
      }
    })
  }
});
