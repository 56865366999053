function is_edit_card() {
  return (location.pathname.includes("edit") &&  location.pathname.includes("locations"));
}

function is_new_card() {
  return location.pathname.includes("new");
}

function resizeMapHeigth() {
  $('.map-information').height($(".address-block").height())
}

$( document ).ready(function() {
  if (is_edit_card()) {
    $('#map-form').locationpicker({
      location: { latitude: $('#lat')[0].value,
                  longitude: $('#lon')[0].value
      },
      radius: 0,
      inputBinding: {
          latitudeInput: $('#lat'),
          longitudeInput: $('#lon'),
      }
    });

  // IS_NEW_CARD
  } else if (is_new_card()) {
    //display map
    $('#map-form').locationpicker({
      location: {latitude: 51.507731, longitude: -0.1308543},
      radius: 0,
      inputBinding: {
          latitudeInput: $('#lat'),
          longitudeInput: $('#lon'),
      }
    });

  // IS_SHOW_CARD
  } else {
    resizeMapHeigth()
    //open map
    if($('.latitude-field').length) {
      $('#map').locationpicker({
          location: {
              latitude: $('.latitude-field')[0].outerText,
              longitude: $('.longitude-field')[0].outerText
          },
          radius: 0,
          draggable: false
      });
    }
  }
});
