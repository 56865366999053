$( document ).ready(function() {
  $('.tooltipped').tooltip();

  $("#custom_url").keypress(function(evt){
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode (key);
    var regex = /\w|-/;
    if ( !regex.test(key) ) {
      theEvent.returnValue = false;
      if(theEvent.preventDefault) theEvent.preventDefault();
    }
  });

  $('#clear-form').click(function(e) {
    e.preventDefault();
    $('input[type=text], input[type=file], textarea').each(function(i, el) {
      $(el).val("")
    });
    $('.uploaded_image').each(function(i, el) {
      $(el).attr('src', '').addClass('hide');
    });
  });

  $('body').on(' change', '.hidden_attached_image', function() {
    if (!$(this).parents('.file-field.input-field').find('.delete_uploaded_image')[0]) {
      $(this).parents('.file-field.input-field').append(clearImageButton)

      $('.btn.red').click(function(e) {
        e.preventDefault();
        var parent = $(e.target).parents('.load-images_block');
        var imageTag = $(parent).find('img');

        $(parent).find('.hidden_attached_image').val('');
        $(parent).find('.file-path.validate').val('');
        imageTag.attr('src', '');
        imageTag.addClass('hide');
        $(parent).find('.delete_uploaded_image').remove();
      });
    }
  });

  $('.btn.red').click(function(e) {
    e.preventDefault();
    var parent = $(e.target).parents('.photo_object');
    var destroyField = $(parent).find('.destroy_image').first();
    var imageTag = $(parent).find('img');

    $(destroyField).val("true");
    $(imageTag).remove();
    $(this).remove();
  });

  $('body').on('change', 'input[type=file]', function(e) {
    var input = e.target;
    if (input.files && input.files[0]) {
      var reader = new FileReader();

      reader.onload = function(e) {
        var imgTag = $(input).parents('.file-field').find('.uploaded_image')
        $(imgTag).attr('src', e.target.result);
        $(imgTag).removeClass('hide');
        $('.photo_object').removeClass('hide')

      }

      reader.readAsDataURL(input.files[0]);
    }
  });

  $('#address').on('change', function(e) {
    removeMapOverlap();
  });

  $('#company_name').on('blur', function(e) {
    if($('#address').val() != "") {
      removeMapOverlap();
    }
  });

  function removeMapOverlap() {
    if($('.map .map-overlap').length){
      $('.map-overlap').remove();
    }
  }
})
