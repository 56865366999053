function is_index_card() {
  return location.pathname.includes("locations") && location.pathname === "/locations";
}

function is_edit_card() {
  return location.pathname.includes("edit");
}

function is_new_card() {
  return location.pathname.includes("new");
}

function getStatusHash(url_params) {
  status_array = url_params.split(/=|\?|&/)
        .filter(function (el) {return el != null && el != ""}
        );

  var parmsHash =  {};
  for ( var i = 0, l = status_array.length; i < l; i=i+2 ) {
    parmsHash[status_array[i]] = status_array[i+1];
  }

  return parmsHash;
}

function closeModal(modalId) {
  $(modalId).modal();
  $(modalId).modal('close');
}

function openModal(modalId) {
  $(modalId).modal();
  $(modalId).modal('open');
}

function copyUrl() {
  var $temp = $("<input>");
  $("#modal1").append($temp);
  $temp.val($('.card-url').text()).select();
  document.execCommand("copy");
  $temp.remove();
}


$( document ).ready(function() {
  // exit any modals
  $('#modal_close, #sending_modal_close').click(function(){
    closeModal('#modal1') || closeModal('#modal2');
  });

  $('form.send_card').on('submit', function(e) {
    $('#send-button').click()
    closeModal('#modal2');
  });

  // IS_INDEX_PAGE
  if(is_index_card()){
    StatusHash = getStatusHash(location.search)
    // open modal after save card
    if(StatusHash.status == "success"){
      openModal('#modal1');
    }
    // open sending modal
    $('.sending_email-button').click(function() {
      closeModal('#modal1');
      openModal('#modal2');
    })
     // copy url
    $('.copy_url-button').click(function() {
      copyUrl()
    })
  }
});

